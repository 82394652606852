#video {
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin-bottom: -3rem;
}

iframe {
    max-height: 85%;
    max-width: 85%;
}