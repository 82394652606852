.Header {
    width: 85%;
    margin: 2rem 4rem 2rem 3rem; 
}

*, 
*:before, 
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: content-box;
}

.z-depth-1-half, 
.btn:hover, 
.btn-large:hover, 
.btn-small:hover, 
.btn-floating:hover {
    box-shadow: 0 .5rem .5rem .25rem;
}

#header_buttons_li {
    display: flex;
    justify-content: center;
}

#header_buttons {
    display: flex;
    width: 50vh;
    margin-top: 1rem;
    justify-content: space-evenly;
}