button {
    background: none;
    border: none;
    border-radius: 20px;
}

button:hover {
    background-color: rgb(193, 226, 215);
    pointer-events: auto;
}

#contact_li {
    display: flex;
    justify-content: center;
}

#contact_buttons {
    display: flex;
    width: 50vh;
    margin-top: 2rem;
    justify-content: space-evenly;
    
}