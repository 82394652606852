.loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-left: 20rem;
}

#loading_heart {
    height: 100%;
} 

