.App {
  text-align: center;
  background-image: url('./1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: wrap;
}

main {
  flex: 1 0 auto;
}

footer {
  padding-top: 0;
  text-align: right;
}

div.HeaderPage {
  justify-content: center;
  width: 100%;
}
